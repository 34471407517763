.content {
  color: var(--app-text-color);
  text-align: left;
  margin-bottom: 184px;

  h2 {
    text-align: center;
    font-size: 36px;
    font-weight: bold;
    color: #292d32;
    // line-height: 50px;
    margin-bottom: 24px;
    padding: 0 1rem;
  }

  .titleDesc {
    text-align: center;
    margin-bottom: 82px;
    font-size: 24px;
    font-weight: 400;
    color: #909093;
    line-height: 34px;
    // padding: 0 300px;
    max-width: 800px;
    margin: 0 auto;
  }

  > .container {
    padding: 1rem 0;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    justify-content: center;
    gap: 24px;
    margin-top: 3rem;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    > .cardContainer {
      min-width: 300px;
      max-width: 300px;
      border-radius: 28px;
      padding: 32px 24px;

      background: #ffffff;
      box-shadow: 0px 2px 12px 0px #e3e6f3;
      border-radius: 16px 16px 16px 16px;
      border: 1px solid #f3f3f3;

      &:last-child {
        margin-right: 0;
      }

      > .userContainer {
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        > .icon {
          width: 46px;
          height: 46px;
          border-radius: 23px;
        }

        > .userName {
          padding-left: 16px;
          font-size: 20px;
          font-weight: 600;
          color: #0c0c0c;
          line-height: 28px;
        }
      }

      .line {
        background: #e6e6e6;
        width: 100%;
        height: 1px;
      }

      .starContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 5px;

        .star {
          margin-top: 16px;
          margin-bottom: 16px;
        }

        .date {
          font-family: Inter, Inter;
          font-weight: 400;
          font-size: 16px;
          color: rgba(12, 12, 12, 0.6);
          line-height: 22px;
          text-align: left;
          font-style: normal;
          text-transform: none;
        }
      }

      > .desc {
        border-radius: 12px;
        min-height: 160px;
        font-size: 18px;
        font-weight: 400;
        color: #0c0c0c;
        line-height: 22px;
        color: #909093;
        text-align: left;
      }
    }
  }
}

@media screen and (min-width: 899px) {
  .content {
    margin-bottom: 184px;
  }
}

@media (max-width: 900px) and (min-width: 768px) {
  .content {
    margin-bottom: 154px;

    .titleDesc {
      padding: 0 1rem;
    }
  }
}

@media (max-width: 769px) and (min-width: 576px) {
  .content {
    margin-bottom: 119px;

    .titleDesc {
      padding: 0 1rem;
    }
  }
}

@media screen and (max-width: 577px) {
  .content {
    margin-bottom: 50px;

    h2 {
      font-size: 32px;
      margin-bottom: 24px;
    }

    .titleDesc {
      padding: 0 1rem;
      font-size: 18px;
    }
  }
}

